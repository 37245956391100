import styled from 'styled-components';

export const Banner = styled.section`
  border-bottom-width: 1px;
  border-color: rgba(224, 231, 255, 1);
  background-color: rgba(243, 244, 246, 1);		
`;

export const Content = styled.div`
  margin-bottom: 2rem;
`;
