import styled from 'styled-components';

export const Title = styled.span`
    color: rgba(49, 46, 129, 1)
`;

export const Authors = styled.span`
    color: rgba(49, 46, 129, 1)
`;

export const Journal = styled.span`
    font-style: italic;
`;
